import React from 'react'
import ContactIcons from './ContactIcons'

import road from '../images/pannonia-cover.jpg'
import angle from '../images/angle.jpg'
import fourCover from '../images/four-cover.jpg'
import birds from '../images/birds.jpg'
import atap from '../images/pannonia-cover-atap.jpg'
import time from '../images/time.jpg'
import redshift from '../images/redshift.jpg'


import Album from './Album'

export default function Disc (props) {
    return (
      <div ref={props.setWrapperRef} id="main" style={props.timeout ? {display: 'flex'} : {display: 'none'}}>
        <article id="shop" className={`${props.article === 'shop' ? 'active' : ''} ${props.articleTimeout ? 'timeout' : ''}`} style={{display:'none'}}>
          <h2 className="major">Discography</h2>

          {albums.map((album, i) =>
            <Album key={i} albumPic={album.albumPic} links={album.links} name={album.name} description={album.description} />
          )}

          <ContactIcons />
          {props.close}
        </article>
        </div>
    )
}

const albums = [
  { name: "REDSHIFT",
    albumPic: redshift,
    description: "Josh Deutsch, trumpet, Nico Soffiato, guitar, Allison Miller and Dan Weiss, drums",
    links: [{'Spotify': 'https://open.spotify.com/album/4if8dUfigkIOgkXqOtFdhi'},
    {"YouTube": 'https://www.youtube.com/playlist?list=PL8TySGPgn9kyGob6LRUt_TLd4d7n6g8Fs'},
    {'Apple Music': 'https://music.apple.com/us/album/redshift/1491972535'
  }]},
  { name: "The Road to Pannonia",
    albumPic: road,
    description: "Josh Deutsch, trumpet/compositions, Zach Brock, violin, Ryan Keberle, trombone, Gary Wang, bass, Ronen Itzik, percussion, Sofia Rei, Nikolett Pankovits & Heather Masse, narration/voice",
    links: [{'CDBaby': 'https://store.cdbaby.com/cd/joshdeutschspannonia2'},
    {"Amazon": 'https://www.amazon.com/dp/B075YBGZC6/ref=cm_sw_r_cp_ep_dp_aXrCAbV4HQ93X'},
    {'iTunes': 'https://itunes.apple.com/us/album/the-road-to-pannonia/1288844460'
  }]},
  { name: "Another Time, Another Place",
  albumPic: atap,
  description: "Josh Deutsch, trumpet/compositions, Zach Brock, violin, Brian Drye, trombone, Gary Wang, bass, Ronen Itzik, percussion",
  links: [{'CDBaby': 'https://store.cdbaby.com/cd/joshdeutschspannonia'},
    {"Amazon": 'https://www.amazon.com/Another-Time-Place-Deutschs-Pannonia/dp/B00H9JXMUK/ref=sr_1_1?s=music&ie=UTF8&qid=1391527719&sr=1-1&keywords=Josh+Deutsch%27s+Pannonia'},
    {'iTunes': 'https://itunes.apple.com/album/another-time-another-place/id780910798?v0=9988'
  }]},
  { name: "Reverse Angle",
  albumPic: angle,
  description: "Josh Deutsch, trumpet, and Nico Soffiato, guitar",
  links: [ {'CDBaby': 'https://store.cdbaby.com/cd/joshdeutschnicosoffiato'},
    {"Amazon": 'https://www.amazon.com/Reverse-Angle-Josh-Deutsch-Soffiato/dp/B00VXWWN2M'},
    {'iTunes': 'https://itunes.apple.com/us/album/reverse-angle/id984559473'
  }]},
  { name: "Time Gels",
  albumPic: time,
  description: "Josh Deutsch, trumpet, and Nico Soffiato, guitar",
  links: [ {'CDBaby': 'https://store.cdbaby.com/cd/joshdeutschandnicosoffia'},
    {'iTunes': 'https://itunes.apple.com/us/album/time-gels/id440867976'}
  ]},
  { name: "Four Across",
  albumPic: fourCover,
  description: "Josh Deutsch, trumpet, Carmen Staaf, piano, Kendall Eddy, bass, and Brian Adler, drums",
  links: [ {'CDBaby': 'https://store.cdbaby.com/cd/fouracross'}]},
  { name: "Live at Jo Federigo's",
  albumPic: birds,
  description: "Josh Deutsch, trumpet, Hashem Assadullahi, saxophone, Jeff Lovell, piano, Dorian McIntyre-Crow, bass, Kevin Congleton,  drums",
  links: [ { "Email Josh": 'mailto:joshdeutschmusic@gmail.com'}]
  },
]
