import React from 'react'
import PropTypes from 'prop-types'
import ContactIcons from './ContactIcons'

import josh7 from '../images/josh7.jpg'

export default function Lessons(props) {
    return (
      <div ref={props.setWrapperRef} id="main" style={props.timeout ? {display: 'flex'} : {display: 'none'}}>
        <article id="lessons" className={`${props.article === 'lessons' ? 'active' : ''} ${props.articleTimeout ? 'timeout' : ''}`} style={{display:'none'}}>
          <h2 className="major">Lessons</h2>
          <span className="image main"><img src={josh7} alt="" /></span>
          <p>Josh has more than a decade of experience teaching children, teens, and adults. He is available for private lessons in trumpet, piano, improvisation, composition, and theory at all levels.</p>

          <p>Email <a href="mailto:joshdeutschmusic@gmail.com">joshdeutschmusic@gmail.com</a> for rates and availability.</p>

          <h3>Testimonials</h3>
          <p className="testimonial">Josh brings more than just talent and knowledge. He has a passion for teaching and an enthusiasm for music that makes him <strong>approachable to the students within minutes</strong>.</p>
          <p className="credit"><em>&mdash;Patrick Refsnider, Director of Bands, Aloha High School</em></p>

          <p className="testimonial"><strong>Every single student of mine that Josh has worked with comes away not only sounding better but also having a greater enthusiasm for playing the trumpet.</strong> Not only does he have deep knowledge of brass technique but he knows how to teach it in a way that makes it accessible for every kind of learner. He combines improvisation technique with legit trumpet skills seamlessly. I have referred many, many students to him!</p>
          <p className="credit"><em>&mdash;Matt McLean, Band Director, Little Red School House</em></p>

          <p className="testimonial">A great friend and mentor, <strong>Josh's guidance has greatly finetuned my technical and creative facilities on the horn</strong>. He has widened the scope of my musical perception and encouraged me to take on ever-advancing artistic endeavors.</p>
          <p className="credit"><em>&mdash;Ben Caiazza, former trumpet student</em></p>

          <p className="testimonial">We hoped to find someone to give the kids the tools to not only enjoy music, but to explore it. The most important gift that Josh has given our kids is the <strong>confidence and skills to make music their own</strong>. Their experience with him has been invaluable.</p>
          <p className="credit"><em>&mdash;Tami Nguyen, parent of piano students Izzy and Q</em></p>

          <p className="testimonial">My favorite thing about Josh is at the end of a lesson we do some free styling and I get to <strong>improvise and rock out!</strong></p>
          <p className="credit"><em>&mdash;Izzy, student</em></p>

          <p className="testimonial">Josh did incredible work with all of our students during his residency at our school. He brings such a <strong>deep wealth of knowledge and experience</strong> to all his endeavors. The work he did with our program, the rehearsals, masterclasses, and improv exercises, have made a <strong>deep and lasting impact on our students</strong> and our program.</p>
          <p className="credit"><em>&mdash;Josh Tower, Director of Instrumental Music, Head-Royce School</em></p>

          <ContactIcons />
          {props.close}
        </article>
        </div>
    )
}

Lessons.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}
