import React from 'react'

export default function ContactIcons(props) {
  return (
    <ul className="icons contact">
      <li><a href="https://www.facebook.com/josh.deutsch.39" className="icon fa-facebook" target="_blank"><span className="label">Facebook</span></a></li>
      <li><a href="https://www.instagram.com/josh.deutsch" className="icon fa-instagram" target="_blank"><span className="label">Instagram</span></a></li>
      <li><a href="https://twitter.com/jdeutschmusic" className="icon fa-twitter" target="_blank"><span className="label">Twitter</span></a></li>
    </ul>
  )
}



