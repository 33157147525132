import React from 'react'

export default function Banner (props) {
    return (
    <div className="banner">
      <div>Now offering online lessons</div>
    </div>
    )
}

