import React from 'react'
import PropTypes from 'prop-types'
import ContactIcons from './ContactIcons'
import Newsletter from './Newsletter'

export default function Contact(props) {
    return (
      <div ref={props.setWrapperRef} id="main" style={props.timeout ? {display: 'flex'} : {display: 'none'}}>
        <article id="contact" className={`${props.article === 'contact' ? 'active' : ''} ${props.articleTimeout ? 'timeout' : ''}`} style={{display:'none'}}>
          <h2 className="major">Contact</h2>
          <div className="contact-me">
            <p>For booking inquiries or lesson availability, email me at <a href="mailto:joshdeutschmusic@gmail.com">
            joshdeutschmusic@gmail.com</a>.
            </p>
            <Newsletter label="Get my newsletter"></Newsletter>

            <div className="listings">
              <h3>Connect with me on social</h3>
              <ContactIcons />
            </div>
          </div>
          <br />
          {props.close}
        </article>
      </div>
    )
}

Contact.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}
