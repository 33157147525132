import React from 'react'
import PropTypes from 'prop-types'
import Bio from './Bio'
import Music from './Music'
import Shows from './Shows'
import Lessons from './Lessons'
import Contact from './Contact'
import Disc from './Disc'

class Main extends React.Component {
  render() {

    let close = <div className="close" onClick={() => {this.props.onCloseArticle()}}></div>

    let propPackage = {
      isArticleVisible: this.props.isArticleVisible,
      timeout: this.props.timeout,
      articleTimeout: this.props.articleTimeout,
      article: this.props.article,
      onCloseArticle: this.props.handleCloseArticle,
      setWrapperRef: this.props.setWrapperRef,
      close: close
    }

    return (
      <div ref={this.props.setWrapperRef} id="main" style={this.props.timeout ? {display: 'flex'} : {display: 'none'}}>
        <Bio {...propPackage } />
        <Music {...propPackage } />
        <Shows {...propPackage } />
        <Lessons {...propPackage } />
        <Disc { ...propPackage } />
        <Contact { ...propPackage } />
      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
}

export default Main
