import React from 'react'

export default function Project (props) {
    return (
      <div>
        <div className="band" onClick={() => { window.scrollTo(0, (200 * props.num)); props.showDetails(props.sect)}}>
          <h2>{props.title}</h2>
        </div>

        {props.show && props.sect === props.section ?
          <div className="details" >
            <div>
              {props.details}
            </div>
          </div>
          :
          <div />
        }
      </div>

    )
}

