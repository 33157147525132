import React from 'react'

const Four = () => (
  <div>
    <p>The quartet is made up of Josh (trumpet), Carmen Staaf (piano), Matt Aronoff (bass), and Brian Adler (drums). Their critically acclaimed debut record incorporates influences from Thelonious Monk and Danilo Perez to stand-up comics, street food, meandering conversations, bicycles, tannins of broccoli, and Danish cinema. Band members have performed in Europe, Asia, and South America and received awards from <em>Downbeat</em> and the Kennedy Center.</p>

    <div className="albums">
      <h3>Album</h3>
      <div className="album">
        <em><h4>Four Across</h4></em>
        <div>2008</div>
        <div>Noted by Bud Young, KBCS FM: "The best new jazz quartet that I've heard in recent years... about to rank among the premier contemporary jazz groups. Fresh, young, and talented."</div>
      </div>
    </div>

    <div className="second-album">
      <h3>Listen</h3>
      <div className="videos">
        <iframe src="https://open.spotify.com/embed/track/4QmoqUowiq2vTojGSdvv2B" width="100%" height="80" frameBorder="0" allowtransparency="true" allow="encrypted-media"></iframe>
      </div>
    </div>

    <div>
      <h3>Watch</h3>
      <div className="videos">
        <iframe width="100%" height="50%" src="https://www.youtube.com/embed/hFFcnxuncK4" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
      </div>
    </div>
  </div>
)

export default Four
