import React from 'react'
import PropTypes from 'prop-types'
import ContactIcons from './ContactIcons'

import josh1 from '../images/josh1.jpg'

export default function Shows(props, { data }) {
    const shows = [];
    return (
      <div ref={props.setWrapperRef} id="main" style={props.timeout ? {display: 'flex'} : {display: 'none'}}>
        <article id="music" className={`${props.article === 'shows' ? 'active' : ''} ${props.articleTimeout ? 'timeout' : ''}`} style={{display:'none'}}>
          <h2 className="major">Shows</h2>

          <div className="listings">
          {shows.length ? shows.map((show, key) =>
            <div key={key} className="show">
              <div className="show-date">
                <div className="date-wrapper">
                  <div className="date">
                    <span className="day">{show.day}</span>
                    <span className="month">{show.month}</span>
                  </div>
                </div>
              </div>
              <div className="show-info">
                <p>{show.city}</p>
                <h3>{show.band}</h3>
                {show.info ?
                <p>{show.info}</p> : ""}
                <p>{show.venue}</p>
                <p>{show.time}</p>
              </div>
              <div className="show-tix">
                <p><a href={show.link} target="_blank"><button>Tickets</button></a></p>
              </div>
            </div>
          ) :
          <p>New dates coming soon!</p>}
          </div>

          <ContactIcons />
          {props.close}
        </article>
        </div>
    )
}

const pastShows = [
  {
    month: "February",
    day: "6",
    city: "Queens, NY",
    band: "Terraza Big Band",
    venue: "Terraza 7",
    time: "8:30pm",
    link: "http://terraza7.com/event/the-terraza-big-band-17/"
  },

  {
    month: "February",
    day: "11",
    city: "Brooklyn, NY",
    band: "Josh Deutsch / Nico Soffiato, feat. Allison Miller",
    info: "REDSHIFT Album Release Show",
    venue: "Bar Lunatico",
    time: "8:30pm",
    link: "https://www.barlunatico.com/calendar/2020/2/11/josh-deutschnico-soffiato-trio-feat-allison-miller-cd-release-show"
  },

  {
    month: "February",
    day: "15",
    city: "Miami, FL",
    band: "Lila Downs",
    venue: "Ground Up Music Festival",
    link: "https://festival.groundupmusic.net"
  },
  {
    date: "February 27, 2020",
    month: "February",
    day: "27",
    city: "Phoenix, AZ",
    band: "Lila Downs",
    venue: "Musical Instrument Museum",
    link: "https://www.liladowns.com/language/en/"
  },
  {
    date: "February 28, 2020",
    month: "February",
    day: "28",
    city: "Phoenix, AZ",
    band: "Lila Downs",
    venue: "Musical Instrument Museum",
    link: "https://www.liladowns.com/language/en/"
  },
  {
    date: "March 1, 2020",
    month: "March",
    day: "1",
    city: "Santa Fe, NM",
    band: "Lila Downs",
    venue: "Lensic Performing Arts Center",
    link: "https://www.liladowns.com/language/en/"
  },
  {
    date: "March 2, 2020",
    month: "March",
    day: "2",
    city: "Albuquerque, NM",
    band: "Lila Downs",
    venue: "National Hispanic Cultural Center",
    link: "https://www.liladowns.com/language/en/"
  },
  {
    date: "March 4, 2020",
    month: "March",
    day: "4",
    city: "Austin, TX",
    band: "Lila Downs",
    venue: "Paramount Theatre",
    link: "https://www.liladowns.com/language/en/"
  },
  {
    date: "March 6, 2020",
    month: "March",
    day: "6",
    city: "Houston, TX",
    band: "Lila Downs",
    venue: "Jones Plaza",
    link: "https://www.liladowns.com/language/en/"
  },
  {
    date: "March 7, 2020",
    month: "March",
    day: "7",
    city: "Dallas, TX",
    band: "Lila Downs",
    venue: "Strauss Square",
    link: "https://www.liladowns.com/language/en/"
  },
  {
    date: "March 21, 2020",
    month: "March",
    day: "21",
    city: "Houston, TX",
    band: "Dafnis Prieto Big Band",
    venue: "Da Camera",
    link: "https://www.dacamera.com/?event=dafnis-prieto-big-band&event_date=2020-03-21"
  },
  {
    date: "April 1-5, 2020",
    month: "April",
    day: "1-5",
    city: "Italy",
    band: "Josh Deutsch / Nico Soffiato REDSHIFT Album Release Tour",
    venue: "",
    time: "",
    link: "http://nicosoffiato.com/josh-deutschnico-soffiato"
  },
  {
    date: "May 1, 2020",
    month: "May",
    day: "1",
    city: "Philadelphia, PA",
    band: "Lila Downs",
    venue: "Zellerback Theatre",
    link: "https://www.liladowns.com/language/en/"
  },
  {
    date: "May 3, 2020",
    month: "May",
    day: "3",
    city: "Boston, MA",
    band: "Lila Downs",
    venue: "Berklee Performance Center",
    link: "https://www.liladowns.com/language/en/"
  },
  {
    date: "May 5, 2020",
    month: "May",
    day: "5",
    city: "Durham, NC",
    band: "Lila Downs",
    venue: "Carolina Theater",
    link: "https://www.liladowns.com/language/en/"
  },
  {
    date: "June 13, 2020",
    month: "June",
    day: "13",
    city: "Eugene, OR",
    band: "Josh Deutsch / Nico Soffiato REDSHIFT Album Release Show",
    venue: "Broadway Avenue House Concerts",
    link:"https://www.facebook.com/pages/category/Live-Music-Venue/Broadway-House-Concerts-463873453682090/"
  },
  {
    date: "June 16, 2020",
    month: "June",
    day: "16",
    city: "Seattle, WA",
    band: "Josh Deutsch / Nico Soffiato REDSHIFT Album Release Show",
    venue: "Royal Room",
    link: "https://theroyalroomseattle.com/"
  }
]


Shows.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}
