import React from 'react'

export default function Newsletter (props) {

    return (
      <div>
        <link href="//cdn-images.mailchimp.com/embedcode/horizontal-slim-10_7.css" rel="stylesheet" type="text/css"></link>
        <div id="mc_embed_signup">
          <form action="https://joshdeutsch.us5.list-manage.com/subscribe/post?u=b1e5d957f6d91dd72faf0bb7c&amp;id=358ba38bdc" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank" noValidate>
            <div
            className="newsletter" id="mc_embed_signup_scroll">
              <label htmlFor="mce-EMAIL">{props.label}</label>
              <input type="email" name="EMAIL" className="email" id="mce-EMAIL" placeholder="email address" required />
              <div style={{position: "absolute", left: "-5000px"}} aria-hidden="true">
                <input type="text" name="b_b1e5d957f6d91dd72faf0bb7c_358ba38bdc" tabIndex="-1" />
              </div>
              <div className="clear">
                <input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" className="button" />
              </div>
            </div>
          </form>
        </div>
        </div>)
}
