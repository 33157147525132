import React, { Component } from 'react'
import ContactIcons from './ContactIcons'
import Project from './Project'
import Pannonia from '../pages/pannonia'
import Nico from '../pages/nico'
import Four from '../pages/four'
import pannonia1 from '../images/pannonia-cover.jpg'
import pannonia2 from '../images/pannonia-illust.jpg'


const projects = {
  pannonia: {
    title: "Pannonia",
    description: "Traditional folk music from an imaginary country",
    pics: [pannonia1, pannonia2],
  details: <Pannonia />
  },
  nico: {
    title: "Josh Deutsch / Nico Soffiato Duo",
    pics: [pannonia1, pannonia2],
    details: <Nico />
  },
  four: {
    title: "Four Across",
    pics: [pannonia1, pannonia2],
    details: <Four />
  }
}


export default class Music extends Component {
  constructor() {
    super()
    this.state = {
      show: false,
      section: ''
    }
    this.showDetails = this.showDetails.bind(this)
  }

  showDetails(sect) {
    this.setState({
      show: !this.state.show,
      section: sect
    })
  }

  componentDidUnmount() {

  }

  render() {

    return (
      <div ref={this.props.setWrapperRef} id="main" style={this.props.timeout ? {display: 'flex'} : {display: 'none'}}>
        <article id="music" className={`${this.props.article === 'music' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{display:'none'}}>
          <h2 className="major">Projects</h2>
        <div id="pannonia" className="project">
          <Project title={projects.pannonia.title}
          description={projects.pannonia.description}
          show={this.state.show}
          section={this.state.section}
          showDetails={this.showDetails}
          photos={projects.pannonia.pics}
          details={projects.pannonia.details}
          sect="pannonia"
          num={1} />
        </div>
        <div id="nico" className="project">
          <Project title={projects.nico.title}
          description={projects.nico.description}
          show={this.state.show}
          section={this.state.section}
          showDetails={this.showDetails}
          photos={projects.nico.pics}
          details={projects.nico.details}
          sect="nico"
          num={2} />
        </div>
        <div id="four" className="project">
          <Project title={projects.four.title}
          description={projects.four.description}
          show={this.state.show}
          section={this.state.section}
          showDetails={this.showDetails}
          photos={projects.four.pics}
          details={projects.four.details}
          sect="four"
          num={3} />
        </div>
          <ContactIcons />
          {this.props.close}
        </article>
        </div>
    )
  }
}

