import React, { Component } from 'react'

export default class Album extends Component {
  constructor() {
  super()
  this.state = {
    links: false,
    people: false
  }
  this.show = this.show.bind(this)
}

show(type) {
  this.setState({
    [type]: true
  })
}

  render() {
    return (
      <div>
        <div className="overlay">
          <img className="image main" src={this.props.albumPic} alt="album-cover" />
          <div className="album-info">
            <div className="album-name">
              <h3>{this.props.name}</h3>
            </div>
            <div className="album-details">
              <div className="personnel">
                <h4>Personnel</h4>
                <span className="album-desc">{this.props.description}</span>
              </div>
              <div className="purchase">
                {this.props.name === "REDSHIFT" ?  <h4>Listen</h4>
                :
                <h4>Purchase</h4>}
                <div className="album-buy">{this.props.links.map((link, i) => <div key={i}><a href={`${link[Object.keys(link)[0]]}`} target="_blank">{Object.keys(link)[0]}</a></div>)}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

    )
  }
}

