import React from 'react'
import PropTypes from 'prop-types'
import ContactIcons from './ContactIcons'

import josh8 from '../images/josh8.jpg'
import dafnisJs from '../images/dafnis-standard.jpg'
import sofia from '../images/sofia.jpg'
import teaching from '../images/teaching.jpg'
import lila from '../images/lila.jpg'


export default function Bio(props) {
    return (
      <div ref={props.setWrapperRef} id="main" style={props.timeout ? {display: 'flex'} : {display: 'none'}}>
        <article id="intro" className={`${props.article === 'intro' ? 'active' : ''} ${props.articleTimeout ? 'timeout' : ''}`} style={{display:'none'}}>
          <h2 className="major">Bio</h2>

          <p>Grammy-winning trumpeter and composer Josh Deutsch is a versatile and unique voice on New York City’s contemporary music scene. He performs regularly in the United States, Europe, Canada, and Asia.</p>

          <span className="image main"><img src={josh8} alt="" /></span>

          <p>Deutsch leads the band Pannonia, whose two albums have garnered acclaim from critics and audiences. <em>Downbeat Magazine</em> awarded four stars to the album <em>The Road to Pannonia</em> and chose Pannonia’s debut album, <em>Another Time, Another Place</em> as an editor's pick, calling it ”a well-planned, beautifully executed program" and praising the "superb solos," "incredible humor" and "deep compositions." Deutsch also performs in a duo with guitarist Nico Soffiato. They have toured extensively in Italy and North America. <em>Guitar International</em> hailed their recording <em>Time Gels</em> (2011) as “an elegant, fresh take on the timeless (duo) tradition” and praised the duo’s ability to “perform as a cohesive unit with improvisational vigor and mature musicianship.” The duo’s third album is currently in production and is planned for release in 2020.</p>

          <p>Deutsch has recorded with Argentinian singer/composer <strong>Sofia Rei</strong>, Grammy-winning Mexican singer <strong>Lila Downs</strong>, the <strong>Dafnis Prieto Big Band</strong>,  <strong>Pedro Giraudo’s</strong> Expansions Big Band, Venezuelan guitarist/singer <strong>Juancho Herrera</strong>, Hungarian singer <strong>Nikolett Pankovits</strong>, Israeli singer/pianist <strong>Noa Fort</strong>, Australian soul/funk band <strong>The Hipstones</strong>, and Colombian folkloric band <strong>Grupo Rebolu</strong>. He has performed with <strong>Darcy James Argue's Secret Society</strong> and the Duke Ellington Big Band directed by <strong>Victor Goines</strong>.</p>

          <span className="image main caption">
            <img src={sofia} alt="" /><p><em>Sofia Rei</em></p>
          </span>

          <span className="image main caption">
            <img src={lila} alt="" />
            <p><em>Lila Downs Band</em></p>
          </span>

          <p>He has presented his own projects at <strong>Dave Douglas’s Festival of New Trumpet Music</strong> as well as at the <strong>Earshot Jazz Festival</strong> in Seattle and the <strong>Outpost Summer Music Festival</strong> in Albuquerque and appeared at such leading venues in the U.S. as <strong>Carnegie Hall</strong>, <strong>The Jazz Standard</strong>,<strong>Birdland</strong>, <strong>Dizzy's Club Coca Cola</strong>, <strong>The Blue Note</strong>, <strong>The Jazz Gallery</strong> and <strong>The Stone</strong> in New York City and <strong>SF Jazz</strong> and  <strong>Yoshi's</strong> in California.</p>

          <span className="image main caption">
            <img src={dafnisJs} alt="" />
            <p><em>Dafnis Prieto Big Band</em></p>
          </span>

          <p>Deutsch writes music in multiple genres and his compositions and arrangements have been performed by many artists, including the <strong>Seattle Symphony Orchestra</strong>, cellist <strong>Ruth Marshall</strong>, the <strong>Oregon Jazz Ensemble</strong>, <strong>Ron Miles</strong>, and <strong>Danilo Pérez</strong>. He has received commissions from the NPR radio program <em>From The Top</em>, the Seattle Symphony Orchestra, Music Northwest and the Head-Royce School. In addition to having composed hundreds of pieces for various performing ensembles, Deutsch has also written several long-form pieces including <em>The Ligeti Project</em>,” a 12-movement, cross-genre work for jazz septet that has been performed on both the East and West Coasts that is based loosely on Györgi Ligeti’s Musica Ricercata.</p>

          <p>Deutsch is an active music educator with experience teaching students and ensembles at all levels and has appeared as a clinician and conducted master classes throughout the country. He was on the faculty of the <strong>Oregon Festival of American Music’s Summer Jazz Academy</strong> from 2007 through 2009, and he has taught in the <strong>University of Oregon Summer Jazz Camp</strong> from 2007 to the present. In 2008, he received the <strong>Excellence in Teaching Award from the University of Oregon</strong> for his work with large and small jazz ensembles and private improvisation students. He currently is the program chair of the <strong>Queens College CPSM Jazz Department</strong>.</p>

          <span className="image main caption">
            <img src={teaching} alt="" />
            <p><em>Leading jazz band clinic at Queens Jazz Overground Festival.</em></p>
          </span>

          <p>A native of Seattle, Deutsch started playing piano and composing music at the age of five before discovering an affinity for the trumpet as a member of award-winning jazz bands at Washington Middle School and Garfield High School. He received a <strong>bachelor's degree in Jazz Studies from the New England Conservatory of Music</strong> in Boston, where he studied with Danilo Pérez, Bob Brookmeyer, John McNeil, Allan Chase and composer Lee Hyla. A three-time participant at the <strong>Banff International Jazz Workshop</strong>, Deutsch worked closely there with Bill Frisell, Dave Douglas, and Kenny Werner among others. In 2006, he was awarded a Graduate Teaching Fellowship from the University of Oregon from which he received a <strong>Masters of Music in Jazz Performance and Composition</strong> in 2009. He won the Reno International Jazz Festival’s Outstanding College Trumpeter award in 2008 and 2009 and is also a winner of a Downbeat Student Music Award as a College Jazz Soloist in 2008.</p>
          <ContactIcons />
          {props.close}
        </article>
        </div>
    )
}

Bio.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}
