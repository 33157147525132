import React from 'react'

const Pannonia = () => (
  <div>
    <p>Pannonia performs traditional folk music from an imaginary place. With original compositions by Deutsch, the quintet features Deutsch on trumpet, violinist <strong>Zach Brock</strong> and trombonist <strong>Ryan Keberle</strong>, with <strong>Gary Wang</strong> on bass and a collection of percussion instruments from around the world played by <strong>Ronen Itzik</strong>.</p>

    <div className="albums">
      <h3>Albums</h3>
      <div className="album">
        <em><h4>The Road to Pannonia</h4></em>
        <div>2018</div>
        <div>A series of narrated pieces that bring the mythology of Pannonia to life, featuring stories by award-winning author <strong>Chris Tarry</strong>, artwork by <strong>Kendall Eddy</strong>, and special guest vocals and narration from <strong>Sofia Rei</strong> (Bobby McFerrin, John Zorn), <strong>Nikolett Pankovits</strong> and <strong>Heather Masse</strong> (The Wailin' Jennies, Prairie Home Companion).</div>
      </div>
      <div className="second-album">
        <em><h4>Another Time, Another Place</h4></em>
        <div>Alternate Side Records, 2014</div>
        <div>Editor’s Pick by <em>DownBeat Magazine</em>: “A well-planned, beautifully executed program” with “odd meters, unusual instrumentation, deep compositions, [and] a clearly drawn fantasy world.”</div>
      </div>
    </div>

    <div className="second-album">
      <h3>Listen</h3>
      <div className="videos">
      <iframe src="https://open.spotify.com/embed/track/7p9gmIDvLriOwqEEcRonry" width="100%" height="80" frameBorder="0" allowtransparency="true" allow="encrypted-media"></iframe>
      <iframe src="https://open.spotify.com/embed/track/4zvsi8y3d0OHnxKGuC1ZM7" width="100%" height="80" frameBorder="0" allowtransparency="true" allow="encrypted-media"></iframe>
      </div>
    </div>

    <div>
      <h3>Watch</h3>
      <div className="videos">
      <iframe width="100%" height="50%" src="https://www.youtube.com/embed/ffWajYbMi2E?rel=0&amp;controls=0&amp;showinfo=0" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
      <iframe width="100%" height="50%" src="https://www.youtube.com/embed/ZowHXK6evcI?rel=0&amp;controls=0&amp;showinfo=0" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
      </div>
    </div>
  </div>
)

export default Pannonia
