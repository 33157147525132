import React from 'react'

const Nico = () => (
  <div>
    <p>Josh and guitarist Nico Soffiato have been playing together since 2006. They have performed extensively throughout the United States and Italy.</p>

    <div className="albums">
      <h3>Albums</h3>
      <div className="album">
        <em><h4>REDSHIFT</h4></em>
        <div>2020</div>
        <div>For their third album, Deutsch and Soffiato have added two of New York’s top drummers: Allison Miller and Dan Weiss, each of whom bring highly individual approaches to the drums. The overall aesthetic has deep roots in jazz, but it also brings a rock energy and attitude. As in their previous albums, <em>Redshift</em> features a reimagined arrangement from the classical repertoire, in this case a tango and balkan inspired take on Schumann’s Piano Quintet. Deutsch and Soffiato also co-composed the song “Consolation Prize” and collaborated on arranging covers of songs by Sufjan Stevens and the band Big Thief.</div>
      </div>

      <div className="second-album">
        <em><h4>Reverse Angle</h4></em>
        <div>2015</div>
        <div>Noted by the <em>New York City Jazz Record</em>: "An invigorating approach to melody and harmony..., displaying an impressive command of diverse styles and textures.... Through solid arrangements, bold colors and excellent harmonic balance, Deutsch and Soffiato make <em>Reverse Angle</em> truly memorable"</div>
      </div>

      <div className="second-album">
        <em><h4>Time Gels</h4></em>
        <div>2011</div>
        <div>A series of narrated pieces that bring the mythology of Pannonia to life, featuring stories by award-winning author <strong>Chris Tarry</strong>, artwork by <strong>Kendall Eddy</strong>, and special guest vocals and narration from <strong>Sofia Rei</strong> (Bobby McFerrin, John Zorn), <strong>Nikolett Pankovits</strong> and <strong>Heather Masse</strong> (The Wailin' Jennies, Prairie Home Companion).</div>
      </div>
    </div>

    <div className="second-album">
      <h3>Listen</h3>
      <div className="videos">
      <iframe src="https://open.spotify.com/embed/track/4r766bLSrLB5nC6uSKVz3U"
      width="100%" height="80" frameBorder="0" allowtransparency="true" allow="encrypted-media"></iframe>
      <iframe src="https://open.spotify.com/embed/track/6pdQiA2YeD9TqlW5F3NVvg" width="100%" height="80" frameBorder="0" allowtransparency="true" allow="encrypted-media"></iframe>
      </div>
    </div>

    <div>
      <h3>Watch</h3>
      <div className="videos">
      <iframe width="100%" height="50%" src="https://www.youtube.com/embed/Va3IkS6-Zxs"  frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
      <iframe width="100%" height="50%" src="https://www.youtube.com/embed/8sHuY_LmfdU?rel=0&amp;controls=0&amp;showinfo=0" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
      </div>
    </div>
  </div>
)

export default Nico
